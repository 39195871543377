<template>
  <div>
    <div style="margin-bottom: 10px">
      <img style="width: 15px" src="../../assets/images/goback.png" alt @click="toBack" />
      <span style="margin-right: 15px" @click="toBack">&nbsp;返回年度审核计划</span>
    </div>
    <div class="_Tab">
      <span
        v-for="(item, index) in ['待审核', '已审核']"
        :key="index"
        :class="{ activeColor: index == tabIndex }"
        @click="toChange(index)"
      >{{ item }}</span>
    </div>
    <div class="mid_box" v-if="isCheck">
      <el-button type="primary" class="edit" @click="addQuestion">
        新增提问
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
      </el-button>
      <span style="margin-top: 5px; margin-left: 10px">
        <img src="../../assets/images/Examine_img/del1.png" alt @click="delItem" />
      </span>
      <el-button style="margin-left: 10px" type="primary" class="edit" @click="getPdf()">打印检查表</el-button>
      <el-button style="margin-left: 10px" type="primary" class="edit" @click="exportExcel">导出检查表</el-button>
    </div>
    <div class="toSave1" @click="toSave" v-if="!isCheck">
      <img style="width: 15px" src="../../assets/images/Examine_img/sava.png" alt />
      <span>完成检查</span>
    </div>
    <div class="_TableBox">
      <el-table
        v-show="isCheck"
        :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        style="width: 100%"
        :height="elementHeight"
        border
        stripe
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
      >
        <el-table-column type="selection" width="50">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked" @change="changeCheck(scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="50"
          :index="(e) => (currentPage - 1) * pageSize + e + 1"
        />
        <el-table-column prop="question" label="问题" width="480"></el-table-column>
        <el-table-column prop="auditResult" label="是否符合要求  " align="center" width="350">
          <template slot-scope="scope">
            <div class="auditResult_box">
              <div
                @click="changeResult(scope.row, 1, '符合')"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >符合</div>
              <div
                @click="changeResult(scope.row, 0, '轻微不符合')"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >轻微不符合</div>
              <div
                @click="changeResult(scope.row, 2, '严重不符合')"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >严重不符合</div>
              <div
                @click="changeResult(scope.row, 4, '不适用')"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >不适用</div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="答案" prop="answer" show-overflow-tooltip></el-table-column> -->
        <el-table-column label="查看答案">
          <template slot-scope="scope">
            <el-button type="primary" class="button" plain @click="isChoose(scope.row)">点击查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-show="!isCheck"
        ref="multipleTable"
        :data="tableData1.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        style="width: 100%"
        :height="elementHeight"
        stripe
        border
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
        @cell-dblclick="handleCell"
      >
        <el-table-column type="selection" width="50">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="50"
          :index="(e) => (currentPage - 1) * pageSize + e + 1"
        />
        <el-table-column prop="question" label="问题"></el-table-column>
        <el-table-column prop="auditResult" label="是否符合要求" align="center" width="350">
          <template slot-scope="scope">
            <div class="auditResult_box">
              <div
                @click="changeResult(scope.row, 1, '符合')"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >符合</div>
              <div
                @click="changeResult(scope.row, 0, '轻微不符合')"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >轻微不符合</div>
              <div
                @click="changeResult(scope.row, 2, '严重不符合')"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >严重不符合</div>
              <div
                @click="changeResult(scope.row, 4, '不适用')"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >不适用</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="imageCount" label="照片列表" align="center" width="190">
          <template slot-scope="scope">
            <div class="img_box" @click="openImg(scope.row)">
              <img src="../../assets/images/Examine_img/add.png" alt />
              <span v-if="scope.row.imageCount === 0">请添加图片</span>
              <span v-else style="margin-right: 50%">{{ scope.row.imageCount }}</span>
              <img src="../../assets/images/Examine_img/picture.png" alt />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="lawAsk" label="审核意见（双击列表）" width="250">
          <template slot-scope="scope">
            <input
              v-if="
                auditId === scope.row.id && columnID === scope.column.id && status != 2
              "
              type="text"
              @focus="compare(scope.row.lawAsk)"
              @blur="Updata(scope.row, scope.row.lawAsk)"
              v-model="scope.row.lawAsk"
            />
            <span v-else>{{ scope.row.lawAsk }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="答案" prop="answer" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.answer }}</template>
        </el-table-column>-->
        <el-table-column label="查看答案" prop="answer" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="primary" class="button" plain @click="isChoose(scope.row)">点击查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="isCheck ? tableData.length : tableData1.length"
        class="_Pagination"
      ></el-pagination>
    </div>

    <el-dialog title="新增提问" :visible.sync="dialogVisible" width="500px">
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <div style="margin-right: 10px; width: 65px">问题描述</div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="form.question"
            maxlength="500"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddASK">确定提问</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看/添加图片" :visible.sync="dialogVisibleimg" width="40%">
      <div v-show="status != 2">上传图片</div>
      <el-upload
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="token"
        v-show="status != 2"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div>已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px; margin-right: 3px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>

        <i
          class="el-icon-delete-solid"
          v-show="index === Curindex && status != 2"
          @click="delImg(item)"
        ></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看答案" :visible.sync="dialogVisibleAsk" width="40%">
      <div style="font-size: 18px">答案:{{ answer }}</div>
      <div style="font-size: 18px">图片:</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px; margin-right: 3px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleAsk = false">确 定</el-button>
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核结果" :visible.sync="dialogVisibleresult" width="35%">
      <el-form label-width="80px">
        <el-form-item label="审核结果:">
          <span>{{ state }}</span>
        </el-form-item>
        <el-form-item label="审核意见:">
          <el-input v-model="form.lawAsk" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="上传照片:">
          <el-upload
            action=" https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :on-preview="handlePictureCardPreview"
            :http-request="uploadFile"
            :headers="token"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>已上传图片</div>
          <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
            <el-image
              style="width: 100px; height: 100px; margin-right: 3px"
              :src="item"
              :preview-src-list="srcList"
              @mouseover="overimg(index)"
            ></el-image>
            <i class="el-icon-delete-solid" v-show="index === Curindex" @click="delImg(item)"></i>
          </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleresult = false">取消</el-button>
        <el-button type="primary" @click="toSure">确认</el-button>
      </span>
    </el-dialog>
    <el-table
      id="pdfDom"
      :data="tableAllData"
      style="width: 100%; position: absolute; left: -100%; z-index: -999;"
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="question" label="问题"></el-table-column>
      <el-table-column
        prop="auditResult"
        label="是否符合要求  "
        v-if="!this.isshowAns"
        align="center"
        width="350"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div :class="{ auditactive: scope.row.auditResult === 1 }">符合</div>
            <div :class="{ auditactive: scope.row.auditResult === 0 }">轻微不符合</div>
            <div :class="{ auditactive: scope.row.auditResult === 2 }">严重不符合</div>
            <div :class="{ auditactive: scope.row.auditResult === 4 }">不适用</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imageCount"
        label="照片列表"
        align="center"
        width="190"
        v-if="!this.isshowAns"
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt />
            <span v-show="scope.row.imageCount === 0">请添加图片</span>
            <span
              v-show="scope.row.imageCount > 0"
              style="margin-right: 50%"
            >{{ scope.row.imageCount }}</span>
            <img src="../../assets/images/Examine_img/picture.png" alt />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="answer" label="答案"></el-table-column>
      <el-table-column prop="lawAsk" label="审核意见">
        <template slot-scope="scope">
          <span>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get, post, put, deleteMethod, baseURL } from "../../api/http";
import { compressImage } from "../../modules/CompressImage";
import { exportExcel } from "../../modules/ExportExcel";
export default {
  data() {
    return {
      token: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      BUrl: baseURL[process.env.NODE_ENV + ""],
      htmlTitle: "年度审核计划",
      elementHeight: 0,
      isCheck: true,
      tabIndex: 0,
      tableData: [], // 待审核
      tableData1: [], // 已审核
      tableAllData: [],
      tableRowIndex: null,
      PlanId: this.$route.query.PlanId,
      status: this.$route.query.status,
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false, // 新增
      dialogVisibleresult: false, // 审核
      dialogVisibleimg: false, // 上传图片
      dialogVisibleAsk: false, // 查看答案
      form: {
        question: "",
      },
      srcList: [],
      state: "",
      Cid: null,
      Curindex: null,
      auditResult: null,
      auditId: null,
      curlawask: "",
      columnID: null,
      answer: "",
      isshowAns: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 10 + 20 + 64 + 40 + 70); //70是div底部距离底部的高度
      });
    },
    toChange(i) {
      this.tabIndex = i;
      this.isCheck = this.tabIndex == 0 ? true : false;
    },
    getList() {
      get("/api/SupplierCheckplanlist?PlanId=" + this.PlanId).then((res) => {
        res.data.forEach((item) => {
          item.checked = false;
        });
        this.tableData = res.data.filter((item) => item.auditResult == null); // 待审核
        this.tableData1 = res.data.filter((item) => item.auditResult != null); // 已审核
        this.tableAllData = res.data; // 全部
        this.tableRowIndex = null;
      });
    },
    // 导出
    exportExcel() {
      this.isshowAns = true;
      this.$nextTick(() => {
        exportExcel("#pdfDom", "年度审核计划.xlsx");
        this.isshowAns = false;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    rowClick(row) {
      this.form = row;
      this.tableRowIndex = row.index;
      this.auditId = row.id;
      this.tableData.forEach((item) => {
        if (row.id == item.id) {
          item.checked = !row.checked;
          if (!item.checked) {
            // this.form = {};
            this.tableRowIndex = null;
          }
        } else {
          item.checked = false;
        }
      });
    },
    changeCheck(row) {
      this.rowClick(row);
    },
    handleCell(row, column) {
      this.columnID = column.id;
    },
    compare(e) {
      this.curlawask = e;
    },
    Updata(row, e) {
      this.columnID = "";
      if (this.curlawask === e) return;
      put("/api/SupplierCheckplanlist", row).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "更改成功",
          });
        } else {
          this.$message.error(res.message);
          row.lawAsk = "";
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    // 新增提问
    addQuestion() {
      if (this.tableData1.length != 0) {
        this.$message({
          type: "warning",
          message: "已有题目审核过,不可重新抽题",
        });
        return;
      }
      this.dialogVisible = true;
      this.form.question = "";
      this.form.id = 0;
    },
    // 确定提问
    toAddASK() {
      this.form.planId = this.PlanId;
      post("/api/SupplierCheckplanlist", this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success("新增成功");
          this.dialogVisible = false;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 删除
    delItem() {
      if (!this.form.id) {
        this.$message.warning("请选择要删除的题目");
        return;
      }
      this.$confirm("此操作将永久删除该题目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod("/api/SupplierCheckplanlist?Id=" + this.form.id).then(
            (res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 审核
    changeResult(row, num, state) {
      if (this.status == 2) {
        this.$message({
          type: "warning",
          message: "已完成检查，禁止修改",
        });
        return;
      }
      this.dialogVisibleresult = true;
      this.state = state;
      this.Cid = row.id;
      this.getImage();
      this.form = row;
      this.auditResult = num;
    },
    // 审核结果
    toSure() {
      this.form.auditResult = this.auditResult;
      put("/api/SupplierCheckplanlist", this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.dialogVisibleresult = false;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 完成检查
    toSave() {
      put("/api/SupplierCheckplan/Complete?PlanId=" + this.PlanId).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("审核完成");
            this.status = 2;
            this.$router.push({
              query: { ...this.$route.query, status: this.status },
            });
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    isChoose(e) {
      this.srcList = [];
      this.answer = e.answer;
      this.dialogVisibleAsk = true;
      if (e.questionImageAddr) {
        var imgdata = e.questionImageAddr.split(",");
        imgdata.forEach((e) => {
          this.srcList.push(this.BUrl + "/SupplierUploads/" + e);
        });
      }
    },
    openImg(e) {
      this.Cid = e.id;
      this.dialogVisibleimg = true;
      this.getImage();
    },
    toPostImg() {
      this.dialogVisibleimg = false;
      this.$refs.upload.clearFiles();
      this.getList();
    },
    getImage() {
      this.srcList = [];
      get("/api/SupplierChecklistimages/" + this.Cid).then((res) => {
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
      });
    },
    handlePictureCardPreview() {
      this.dialogVisiblebig = true;
    },
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          if (result.size > e.file.size) {
            this.uploadFileReq(e.file);
          } else {
            this.uploadFileReq(result);
          }
        });
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadSupplierImg", formData)
        .then((res) => {
          this.$refs.upload.clearFiles();
          if (res.code == 200) {
            var data = {
              CheckListId: this.Cid,
              ImageRemark: "",
              ImageAddress: this.BUrl + "/SupplierUploads/" + res.value,
              saveUserId: this.$store.state.userInfo.id,
            };
            post("/api/SupplierChecklistimages", {
              data: JSON.stringify([data]),
            }).then(() => {
              this.$refs.upload.clearFiles();
              this.getImage();
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },
    overimg(index) {
      this.Curindex = index;
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteMethod("/api/SupplierChecklistimages?Id=" + e.id).then(
                (res) => {
                  if (res.code == 200) {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    this.getImage();
                  }
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.tableData.map((item) => {
        item.checked = false;
      });
      this.tableRowIndex = null;
      this.form.id = null;
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.tableData.map((item) => {
        item.checked = false;
      });
      this.tableRowIndex = null;
      this.form.id = null;
    },
  },
};
</script>

<style scoped>
@import "../../assets/style/Examine_CSS.css";
</style>
